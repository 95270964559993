// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/header-bg-mobile.webp");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/header-bg-desktop.webp");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eo-renderer .eo-flockler+.eo-drawer .eo-drawer__content{max-height:calc(100vh - 134px)}.beam-header .eo-header__bottom-bg,.beam-header .eo-header__top-toolbar .eo-toolbar__background{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top;background-size:100% auto}@media(min-width:900px){.beam-header .eo-header__bottom-bg,.beam-header .eo-header__top-toolbar .eo-toolbar__background{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}}.beam-header .eo-header__bottom-bg.is-left{background-position:left top -24px;background-size:200% auto}.beam-header .eo-header__bottom-bg.is-right{background-color:#1a004d!important;background-position:right top -24px;background-size:200% auto}.beam-header .eo-header__bottom .eo-toolbar__background{opacity:.75}.brand-beam{margin:-10px 0 -10px 10px!important}@media(min-width:360px){.brand-beam{margin-left:0!important}}@media(min-width:900px){.brand-beam{margin-left:10px!important}}.brand-eo{margin:-10px -100% -10px auto!important}@media(min-width:360px){.brand-eo{margin-left:20px!important;margin-right:0!important}}.brand-npo.eo-broadcasters{display:none}@media(min-width:360px){.brand-npo.eo-broadcasters{display:inline-flex}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
