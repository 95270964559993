
export default {
  data () {
    return {
      isMounted: false,
      isMenuOpen: false,
      headerActiveTab: null,
      keyword: null,
      menuPrimaryItems: {
        Home: '/',
        'Programma\'s': {
          expand: true,
          children: {
            'BEAM Kerkdienst': '/programmas/eo-beam-kerkdienst',
            Denkstof: '/programmas/denkstof',
          },
        },
        'EO-Jongerendag': '/evenement/eo-jongerendag',
        Magazine: '/magazine',
        'BEAM-wear': this.$config.baseUrlMerchandise,
        Contact: '/contact',
      },
      menuSecondaryItems: {
        'BEAM Team': '/over-beam/team',
        'Over BEAM': '/over-beam',
        'Word lid': this.$config.baseUrlMembership,
        Stage: '/over-beam/stage',
        'Adverteren bij BEAM Magazine': '/adverteren',
      },
      menuSocialItems: {
        tiktok: 'https://www.tiktok.com/@eobeam',
        instagram: 'https://www.instagram.com/eobeam/',
        youtube: 'https://www.youtube.com/eobeam',
        whatsapp: 'https://wa.me/31615878111',
        spotify: 'https://open.spotify.com/user/eobeam/',
        facebook: 'https://www.facebook.com/jongeren/',
      },
      footerShortcuts: {
        'Ga naar...': {
          'Vraag & antwoord': '/over-beam/vraag-en-antwoord',
          Communityrichtlijnen: '/communityrichtlijnen-beam',
          'Over BEAM': '/over-beam',
          'Adverteren BEAM': '/adverteren',
          Contact: '/contact',
          Stage: '/over-beam/stage',
        },
      },
      footerSocials: {
        'Volg ons op...': {
          tiktok: {
            name: 'TikTok',
            path: 'https://www.tiktok.com/@eobeam',
          },
          instagram: {
            name: 'Instagram',
            path: 'https://www.instagram.com/eobeam/',
          },
          youtube: {
            name: 'Youtube',
            path: 'https://www.youtube.com/eobeam',
          },
          whatsapp: {
            name: 'Whatsapp',
            path: 'https://wa.me/31615878111',
          },
          spotify: {
            name: 'Spotify',
            path: 'https://open.spotify.com/user/eobeam/',
          },
          facebook: {
            name: 'Facebook',
            path: 'https://www.facebook.com/jongeren/',
          },
        },
      },
    }
  },
  head: {
    // @todo: move this preload to ccm module itself
    link: [
      {
        rel: 'preload',
        as: 'style',
        href: 'https://cookies.eo.nl/sites/EO/eo.nl/npo-lowerbar_v3.1.10.css',
        crossorigin: '',
      },
    ],
  },
  computed: {
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    onNavigate () {
      this.isMenuOpen = false
    },
    onSearch (keyword) {
      this.$router.push({
        path: '/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
