const middleware = {}

middleware['redirect-publication'] = require('../middleware/redirect-publication.js')
middleware['redirect-publication'] = middleware['redirect-publication'].default || middleware['redirect-publication']

middleware['redirect-trailing-slash'] = require('../middleware/redirect-trailing-slash.js')
middleware['redirect-trailing-slash'] = middleware['redirect-trailing-slash'].default || middleware['redirect-trailing-slash']

middleware['update-google-analytics'] = require('../middleware/update-google-analytics.js')
middleware['update-google-analytics'] = middleware['update-google-analytics'].default || middleware['update-google-analytics']

export default middleware
