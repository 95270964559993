export default function ({ $config: { googleAnalytics }, route: { path } }) {
  if (path.startsWith('/doneren')) {
    googleAnalytics.ecommerce = {
        enabled: true,
      }
  } else {
    googleAnalytics.ecommerce = {
        enabled: false,
      }
  }
}