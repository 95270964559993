// Vue plugins for both server and client side
import Vue from 'vue'
import componentLibrary from '@eo/component-library'
import plugins from "@eo/component-library/src/plugins"

const options = JSON.parse(`{"isNuxt":true,"debug":false,"namespace":"component-library","baseUrl":"https://beam.eo.nl","baseUrlApi":"https://api.eo.nl","cloudflareCdn":"https://beam.eo.nl/cdn-image","preprEnvironment":"beam-eo","themeFile":"~/assets/css/eo.variables.scss","npoTag":{"brand":"Beam","brandId":630012},"EoRenderer":{"Asset":{"class":"my-2","Document":{"target":"_blank"}},"Donation":{"color":"indigo-800","textColor":"white","accentColor":"red-500"},"NewsletterSubscribe":{"accentColor":"red-600","color":"primary","textColor":"white"},"Quote":{"context":"beam"}}}`)
Vue.use(componentLibrary, options)

export default async (context, inject) => {
  // Register all plugins from library also in Nuxt global context
  Object.entries(plugins).forEach(([name]) => {
    inject(name, Vue.prototype['$' + name])
  })
}