import axios from 'axios'
import constants from './constants'

const maxBatchSize = 1000 // Amount of items per call (Prepr only returns a maximum of 1000 items per call)
const maxPageNumber = 25  // Amount of sitemap-pages per resource (for now only implemented for articles)

/**
 * Function to get publications from the Prepr REST API recursively
 * @param {object} config
 * @returns {Array} Slugs of publications
 */
const get = async (config) => {
  const parameters = {
    domain: constants.PREPR_ENVIRONMENT_DOMAIN,
    showLastMod: true,
    ...config,
  }

  const {
    type,
    domain,
    priority,
    resource,
    showLastMod,
    communityId,
    maxAmount,
    page,
    basePath,
  } = parameters

  let limit
  let offset = 0

  const apiHost = process.env.NEW_EO_API_HOST || 'https://api.eo.nl'

  if (page) {
    // 1 determine total amount of articles
    let {data: {meta}} = await axios.get(`${apiHost}/sitemap/${resource}`, {
      params: {
        communityId,
        domain,
        limit: 1,
        offset: 0,
      },
    })
    // 2 determine the page size.
    limit = Math.ceil(meta.total / maxPageNumber)
    offset = (page - 1) * limit
  } else {
    offset = 0
    limit = maxBatchSize > maxAmount ? maxAmount : maxBatchSize
  }

  const params = {
    domain,
    limit,
    offset,
    ...(communityId && { communityId }),
    ...(type === 'rss' && { rss: 'true' }),
  }

  let {data: {items = [] }} = await axios.get(`${apiHost}/sitemap/${resource}`, {
    params, 
  })

  // Check if api call contains results
  if (!items || !items[0]) {
    return []
  }

  // Remove publications excluded from indexing
  switch (resource) {
    case 'articles':
    case 'pages':
    case 'events':
    case 'persons':
    case 'programs':
    case 'podcasts':
    case 'campaigns':
    case 'donations':
      items = items.filter(p => {
        return !p.seoAllow || p.seoAllow === true
      })
      break
    case 'topics':
    case 'pressreleases':
    case 'tags':
    case 'stories':
    default:
      break
  }

  // Return all publications, including the recursive ones
  return [
    ...items.map(p => {
      const fullPath = `${basePath}/${p.slug}`
      if (type === 'sitemap') {
        return {
          lastmod: showLastMod && (p.changedOn || null),
          priority,
          url: fullPath,
        }
      }
      if (type === 'rss') {
        return {
          category: p.tags?.map(tag => {
            return {name: tag.name}
          }),
          description: p.intro?.replaceAll('&', '&amp;'),
          enclosure: p.hero?.replace('{format}', 'w_512'),
          id: constants.BASE_URL_CANONICAL + fullPath,
          link: constants.BASE_URL_CANONICAL + fullPath,
          published: new Date( p.publishOn) || null,
          title: p.title,
          updated: showLastMod && (new Date(p.changedOn) || null),
        }
      }
    }),
  ]
}

const getArticleSitemaps = () => {
  let initalArray = new Array(maxPageNumber).fill({
    path: '',
    exclude: ['/**'],
    routes: null,
  })
  let articleSitemaps = initalArray.map((item,index) => {
    return {
      path: `sitemap-articles-${index+1}.xml`,
      exclude: item.exclude,
      cacheTime: 1000 * 60 * 60 * 24,  // 1 days
      routes: async () => {
        return await get({
          resource: 'articles',
          type: 'sitemap',
          page: index+1,
          basePath: '/artikel',
        })
      },
    }
  })
  return articleSitemaps
}

const getSitemaps = () => {
  return [
    {
      path: '/sitemap.xml',
    },
    {
      path: '/sitemap-authors.xml',
      exclude: ['/**'],
      routes: async () => {
        return await get({
          communityId: constants.PREPR_COMMUNITY_ID,
          domain: 'generieke-content-eo',
          resource: 'persons',
          type: 'sitemap',
          basePath: '/over-beam/team',
        })
      },
    },
    {
      path: '/sitemap-campaigns.xml',
      exclude: ['/**'],
      routes: async () => {
        return await get({
          resource: 'campaigns',
          type: 'sitemap',
          basePath: '/actie',
        })
      },
    },
    {
      path: '/sitemap-programs.xml',
      exclude: ['/**'],
      routes: async () => {
        return [
          ...await get({
            communityId: constants.PREPR_COMMUNITY_ID,
            domain: 'generieke-content-eo',
            resource: 'programs',
            type: 'sitemap',
            basePath: '/programmas',
          }),
          ...await get({
            communityId: constants.PREPR_COMMUNITY_ID,
            domain: 'generieke-content-eo',
            resource: 'podcasts',
            type: 'sitemap',
            basePath: '/programmas',
          }),
        ]
      },
    },
    {
      path: '/sitemap-stories.xml',
      exclude: ['/**'],
      routes: async () => {
        return await get({
          resource: 'stories',
          showLastMod: false,
          type: 'sitemap',
          basePath: '/rubriek',
        })
      },
    },
    {
      path: '/sitemap-pages.xml',
      exclude: ['/**'],
      routes: async () => {
        return await get({
          resource: 'pages',
          showLastMod: false,
          type: 'sitemap',
          basePath: '/',
        })
      },
    },
    {
      path: '/sitemap-tags.xml',
      exclude: ['/**'],
      routes: async () => {
        return await get({
          domain: 'generieke-content-eo',
          resource: 'tags',
          showLastMod: false,
          type: 'sitemap',
          basePath: '/alles-over',
        })
      },
    },
  ]
}

/**
 * @nuxtjs/sitemap
 * Nuxt config for generated sitemaps
 * ref: https://sitemap.nuxtjs.org/
 */
export const sitemap = {
  path: '/sitemap-index.xml',
  hostname: constants.BASE_URL,
  lastmod: new Date().toISOString(),
  gzip: true,
  sitemaps: [
    ...getSitemaps(),
    ...getArticleSitemaps(),
  ],
}

/**
 * @nuxtjs/feed
 * Nuxt config for generated RSS feeds
 * ref: https://github.com/nuxt-community/feed-module
 */
export const rss = async () => {
  return [
    {
      path: `/rss-articles.xml`,
      cacheTime: 1000 * 60 * 15, // 15 minutes
      async create(feed) {
        feed.options = {
          description: `Artikelen van EO BEAM`,
          feedLinks: {
            atom: `${constants.BASE_URL_CANONICAL}/rss-articles.xml`,
          },
          language: 'nl',
          link: constants.BASE_URL_CANONICAL,
          title: `EO BEAM`,
        }
        const articles = await get({
          maxAmount: 100,
          resource: 'articles',
          type: 'rss',
          basePath: '/artikel',
        })
        articles.forEach(p => feed.addItem(p))
      },
      type: 'rss2',
    },
  ]
}
