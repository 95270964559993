
import { dateFormatMixin } from "~/mixins"

export default {
  mixins: [dateFormatMixin],
  data () {
    return {
      // Layout data
      isMounted: false,
      activeTab: null,
      keyword: null,
      // Event data
      isEventInFuture: null,
      isEventStartingToday: null,
      isEventIn30Min: null,
      isEventIn10Sec: null,
      isEventHappening: null,
      isEventStarting: null,
      isEventInPast: null,
      articles: [],
      callToAction: null,
      changedOn: null,
      color: null,
      description: [],
      endDate: null,
      environmentId: null,
      faq: [],
      hero: null,
      heroVideo: null,
      heroVideoStill: null,
      id: null,
      lineUp: [],
      liveContent: null,
      livestream: null,
      location: null,
      logo: null,
      program: [],
      publishOn: null,
      readableDate: null,
      seo: null,
      slug: null,
      social: null,
      startDate: null,
      subtitle: null,
      terms: [],
      tickets: [],
      title: null,
      topic: null,
    }
  },
  async fetch() {
    // Get event for this slug
    const [{ data: { items: events = [] } = {} }] = await Promise.all([
      this.$api.events.getOneBySlug(this.$route.params.slug, {
        domain: this.$config.preprEnvironment,
      }),
    ])

    // Check publication if exists
    if (!events || !events.length) {
      return false
    }

    // Save event data
    this.callToAction = events[0].callToAction
    this.changedOn = events[0].changedOn
    this.color = events[0].color
    this.description = events[0].description
    this.endDate = events[0].endDate
    this.environmentId = events[0].environmentId
    this.faq = events[0].faq
    this.hero = events[0].hero
    this.heroVideo = events[0].heroVideo
    this.heroVideoStill = events[0].heroVideoStill
    this.id = events[0].id
    this.lineUp = events[0].lineUp
    this.liveContent = events[0].liveContent
    this.livestream = events[0].livestream
    this.location = events[0].location
    this.logo = events[0].logo
    this.program = events[0].program
    this.publishOn = events[0].publishOn
    this.seo = events[0].seo
    this.slug = events[0].slug
    this.social = events[0].social
    this.startDate = events[0].startDate
    this.subtitle = events[0].subtitle
    this.terms = events[0].terms
    this.tickets = events[0].tickets
    this.title = events[0].title
    this.topic = events[0].topic

    // Get articles linked to this event
    const { data: { items: articles = [] } = {} } = await this.$api.articles.getAll({
      limit: 3,
      event: this.id,
    })
    this.articles = articles

    // Set breadcrumbs for the page
    this.$store.dispatch("breadcrumbs/set", {
      path: `/evenementen/${this.slug}/live`,
      eoRoot: true,
      floating: true,
      width: 10,
      items: [
        {
          to: "/",
          label: "BEAM",
        },
        {
          label: this.title,
        },
      ],
    })
  },
  head() {
    return {
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          hid: 'test-script',
          innerHTML: `(function (m, a, z, e) {
              var s, t;
              try {
                t = m.sessionStorage.getItem('maze-us');
              } catch (err) {}

              if (!t) {
                t = new Date().getTime();
                try {
                  m.sessionStorage.setItem('maze-us', t);
                } catch (err) {}
              }

              s = a.createElement('script');
              s.src = z + '?t=' + t + '&apiKey=' + e;
              s.async = true;
              a.getElementsByTagName('head')[0].appendChild(s);
              m.mazeUniversalSnippetApiKey = e;
            })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '15288e81-fd17-4dd1-b941-ced653dab5ea');`,
          type: 'text/javascript',
          charset: 'utf-8',
        },
      ],
    }
  },
  computed: {
    tabWidth () {
      return (
        12 /
        (1 + Boolean(this.program && this.program.length) + Boolean(this.faq && this.faq.length))
      )
    },
    isCountdownVisible () {
      return this.isEventInFuture || this.isEventStarting || this.isEventInPast
    },
  },
  watch: {
    startDate: {
      handler: function () {
        // Create a readable date every second, if needed
        this.setReadableDate()
        if (this.isEventIn30Min || this.isEventHappening) {
          setInterval(this.setReadableDate.bind(this), 1000)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    errorCaptured(error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
    setReadableDate() {
      if (!this.startDate) return
      this.$moment.relativeTimeThreshold("ss", 0)

      // Get relative dates
      const now = this.$moment()
      const start = isNaN(this.startDate)
        ? this.$moment(this.startDate)
        : this.$moment.unix(this.startDate)
      const secondsToStart = start.diff(now, "seconds", true)
      const minutesToStart = start.diff(now, "minutes", true)
      const minutesFromStart = now.diff(start, "minutes", true)
      let end
      let minutesToEnd
      let minutesFromEnd
      if (this.endDate) {
        end = isNaN(this.endDate) ? this.$moment(this.endDate) : this.$moment.unix(this.endDate)
        minutesToEnd = end.diff(now, "minutes", true)
        minutesFromEnd = now.diff(end, "minutes", true)
      }

      // Set relative date booleans
      this.isEventStartingToday = start.isSame(now, "day") && minutesToStart > 0
      this.isEventIn30Min = secondsToStart > 0 && minutesToStart < 30
      this.isEventIn10Sec = secondsToStart > 0 && secondsToStart <= 10
      this.isEventInFuture = minutesToStart > 0
      if (this.endDate) {
        this.isEventHappening = secondsToStart <= 0 && minutesToEnd > 0
        this.isEventStarting = this.isEventHappening && secondsToStart > -10
        this.isEventInPast = minutesFromEnd > 0
      } else {
        this.isEventHappening = minutesToStart == 0
        this.isEventInPast = minutesFromStart > 0
      }

      // Generate human readable date
      this.readableDate = this.isEventIn30Min
        ? `We beginnen ${start.fromNow()}`
        : this.isEventIn10Sec
          ? `Nog ${start.fromNow()}`
          : this.isEventStarting
            ? "We zijn begonnen!"
            : this.isEventHappening && this.endDate
              ? `Eindigt ${this.dateFormat.past(this.endDate)}`
              : this.isEventStartingToday
                ? `We beginnen om ${this.dateFormat.time(this.startDate)}u`
                : this.isEventInFuture
                  ? `Begint ${this.dateFormat.dayDateAndTime(this.startDate)}u`
                  : this.isEventInPast && minutesToStart > -5
                    ? "Zojuist plaatsgevonden"
                    : this.isEventInPast && minutesFromEnd < 5
                      ? "Het is afgelopen. Dankjewel dat je er was!"
                      : `Dit evenement is voorbij`
    },
  },
}
