import { render, staticRenderFns } from "./eventLive.vue?vue&type=template&id=529cd235&scoped=true"
import script from "./eventLive.vue?vue&type=script&lang=js"
export * from "./eventLive.vue?vue&type=script&lang=js"
import style0 from "./eventLive.vue?vue&type=style&index=0&id=529cd235&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "529cd235",
  null
  
)

export default component.exports